








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.canotClick {
	cursor: default !important;
}

.home {
	.search {

		::v-deep .ivu-select-selection,
		::v-deep .ivu-select-selected-value,
		::v-deep .ivu-select-placeholder {
			$lh: 0.5rem;
			$lh-wap: $lh * 1.5;
			height: $lh !important;
			line-height: $lh !important;

			@media (max-width: 767px) {
				height: $lh-wap !important;
				line-height: $lh-wap !important;
			}
		}

		.ivu-select-selection {
			background-color: #eeeeee;
			border-radius: 0;
			border: 1px solid #eeeeee;
		}
	}
}
